import logo from './logo.png';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

function App({type}) {


  const GET_PRODUCTS = gql`query {
    products {
      active
      allowNegInventory
      brand
      category
      code
      collection
      description
      discount
      id
      isNew
      price
      productcode
      productID
      productImages
      purchaseprice
      sale
      stock
      tags
      title
      trackInventory
      type
    }
  }
`;

var { loading, data } = useQuery(GET_PRODUCTS);

console.log('data for app ==', data);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We are coming soon online to serve you better !!!
        </p>
      </header>
    </div>
  );
}

export default App;
